import { Component, Inject, OnInit } from "@angular/core";
import { EntityManager } from "../entity.service";
import { AppLogin, AppLoginRoles } from "../app-users/app-login.entity";
import { TransactionService } from "../transaction.service";
import { MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";
import { Project } from "../project/project.entity";

@Component({
  selector: "app-app-login-dialog",
  templateUrl: "./app-login-dialog.component.html",
  styleUrls: ["./app-login-dialog.component.scss"],
})
export class AppLoginDialogComponent implements OnInit {
  model: AppLogin;
  selectedProject: Project;

  projectFilterConfig = {
    allowNothing: true,
    title: "Project",
    icon: "archive",
    entityName: "projects",
    nameField: "id",
    descriptionField: "description",
    sortField: "id",
    sortDirection: "ASC",
    filterFields: ["id", "description"],
    filters: [],
  };

  protected get appLoginService() {
    return this.entityManager.get(AppLogin);
  }

  get canSave() {
    return this.model.email && this.model.project_id;
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppLoginDialogComponentData,
    protected readonly entityManager: EntityManager,
    protected readonly transactions: TransactionService,
    protected readonly dialog: MdcDialogRef<AppLoginDialogComponent>
  ) {}

  ngOnInit() {
    this.model = this.appLoginService.concept({
      role_id: AppLoginRoles.Customer,
      project_id: this.data.variables.projectId,
    });
  }

  async save() {
    const response = await this.transactions.perform(() =>
      this.appLoginService.save(this.model)
    );

    if (!response.hasError()) {
      this.dialog.close(response.value);
    }
  }
}

export class AppLoginDialogComponentData {
  constructor(
    readonly variables: {
      readonly projectId: string;
    }
  ) {}
}
