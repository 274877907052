import { Entity } from "../entity.service";
import { PlanningCompetence } from "../picklist-overview/planning-project-item.entity";
import { Project } from "../project/project.entity";
import { User } from "../accessibility-users/user.entity";

export class HousePart extends Entity {
  type = "house_parts";

  housePartGroupId: string;

  __housePartGroup__?: HousePartGroup;

  width: string;
  length: string;
  capacityFactor?: number;

  description: string;
  rootElement: string;
  releaseTimeMins?: number;
}

export class HousePartGroup extends Entity {
  type = "house_part_groups";

  name: string;
  isEnabled: boolean;

  cargoId?: string;
  divisionId?: number;
  unit?: string;

  isUpdated?: boolean;
  isPicked?: boolean;
  isExpanded?: boolean;

  state?: CargoState;
  elements?: ProjectHousePart[];

  highestWidth?: string;
  highestLength?: string;
}

export class ProjectHousePart extends Entity {
  type = "project_house_parts";

  cargoId: string;
  groupDivisionId: string;
  partCodeId: string;
  projectId: string;

  unit: string;
  quantity: number;
  weight: string;

  length?: string;
  width?: string;

  moldId?: string;
  dateId?: string;
  wallId?: string;

  isSplit?: boolean;

  get project() {
    return this.__project__;
  }

  __project__?: Project;
  __housePart__?: HousePart;

  get housePart() {
    return this.one(ProjectHousePart, HousePart, "housePart");
  }
}

export class CargoType extends Entity {
  type = "cargo_types";

  userId: string;
  description: string;
  colorCode: string;

  __user__?: User;
}

export interface ICargoPhase {
  readonly id: string;
  readonly description: string;
  readonly type: string;

  readonly cargoIdField: string;
  readonly cargoEntityField: string;
}

export enum CargoPhaseType {
  Competence = "planning_competences",
  Custom = "cargo_phases",
}

export class CargoPhase extends Entity implements ICargoPhase {
  type = "cargo_phases";

  description: string;
  date: Date;

  cargoIdField = "phaseId";
  cargoEntityField = "__phase__";
}

export class Cargo extends Entity {
  type = "cargos";

  projectId: string;
  cargoTypeId: string;

  phaseId?: string;
  competenceId?: string;

  dateAt: Date;
  timeAt: string;

  description: string;
  notes: string;
  routeId: string;

  isHidden: boolean;
  isLocked: boolean;
  isExpanded?: boolean;

  requiresPermit: boolean;

  __cargoType__?: CargoType;
  __competence__?: PlanningCompetence;
  __phase__?: CargoPhase;

  __project__?: Project;
  __projectHouseParts__?: ProjectHousePart[];

  __cargoStates__: CargoState[];

  elementGroups?: HousePartGroup[] = [];

  get projectHouseParts() {
    return this.many(Cargo, ProjectHousePart, "projectHouseParts");
  }

  get phaseDescription() {
    const morphed: ICargoPhase = this.__competence__ || this.__phase__;

    return morphed && morphed.description;
  }

  get resolvedPhaseId() {
    const morphed: ICargoPhase = this.__competence__ || this.__phase__;

    return morphed && morphed.id;
  }

  get phaseType() {
    return this.competenceId
      ? CargoPhaseType.Competence
      : CargoPhaseType.Custom;
  }

  isPhaseMatch(phase: ICargoPhase) {
    return this[phase.cargoIdField] === phase.id;
  }
}

export class CargoState extends Entity {
  type = "cargo_states";

  cargoId: string;
  housePartGroupId: string;
  divisionId?: string;

  value: string;
}

export const cargoRoutes: ICargoRoute[] = [
  { id: "zwolle_bouw", label: "Kampen -> Bouw" },
  { id: "zwolle_emmeloord_bouw", label: "Kampen -> Emmeloord -> Bouw" },
  { id: "emmeloord_bouw", label: "Emmeloord -> Bouw" },
  { id: "retour", label: "Retour" },
  { id: "nalevering", label: "Na-levering" },
  { id: "none", label: "Geen" },
];

export interface ICargoRoute {
  id: string;
  label: string;
}
