import { MdcSnackbar } from "@angular-mdc/web";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { chain, first } from "lodash";
import * as moment from "moment";
import { FileSystemFileEntry, NgxFileDropEntry } from "ngx-file-drop";
import { AuthService } from "../auth.service";
import { CellaService } from "../cella.service";
import { EntitySelectConfig } from "../entity-select/entity-select.component";
import { MetacomService } from "../metacom.service";
import { Project } from "../project/project.entity";
import { ConfigurationEntity } from "../quality-assurance.service";
import { RestResponse } from "../rest.service";
import { ShopPresetsConfiguration } from "../shop-presets/ShopPresets";
import { TransactionService } from "../transaction.service";
import { DialogService } from "./../dialog.service";
import { EntityManager } from "./../entity.service";
import { ImageDialogComponent } from "./../image-dialog/image-dialog.component";
import { RestService } from "./../rest.service";
import { Address, ShopItemDto, ShopRequest } from "./shop-item.dto";
import { ShopItemGroup } from "./shop-item.group";
import { ShopDocument } from "./shop.document";
import { ShopMailer } from "./shop.mailer";

@Component({
  selector: "app-shop",
  templateUrl: "./shop.component.html",
  styleUrls: ["./shop.component.scss"],
})
export class ShopComponent implements OnInit {
  readonly DEFAULT_COMPANY_ID = 920;
  readonly GROUP_ID = "990999";

  deliveryDate = null;
  addressType = "project";

  comment: string;
  image: File;

  project: Project;
  projectId: string;

  get projectFilterConfig(): EntitySelectConfig {
    return {
      allowNothing: true,
      title: "Project",
      icon: "archive",
      entityName: this.settings.projectTableName,
      nameField: "id",
      descriptionField: "description",
      sortField: "id",
      sortDirection: "ASC",
      filterFields: ["id", "description"],
      filters: this.settings.conditionFilter
        ? [{ field: "condition", operator: "Equal", value: "1000" }]
        : [],
    };
  }

  get nativeProjectId() {
    return this.projectId.charAt(0) === "S"
      ? this.projectId.substr(1)
      : this.projectId;
  }

  get minShippingDate() {
    return moment().add(2, "days").format("YYYY-MM-DD");
  }

  get settings() {
    return this.route.snapshot.data.settings as ShopComponentSettings;
  }

  protected get deltaDays() {
    const weekDay = moment().weekday();

    return weekDay <= 2 ? 2 : 7 - weekDay;
  }

  get dateConfig() {
    const min = moment().add(this.deltaDays, "days");

    return {
      dateRange: false,
      dateFormat: "dd-mm-yyyy",
      firstDayOfWeek: "su",
      selectorHeight: "auto",
      disableWeekends: true,
      disableUntil: {
        year: min.year(),
        month: min.month() + 1,
        day: min.date(),
      },
    };
  }

  response: RestResponse<ShopItemGroup[]>;
  images: ShopPresetsConfiguration;

  protected get projectService() {
    return this.entities.get(Project);
  }

  protected get configurationService() {
    return this.entities.get(ConfigurationEntity);
  }

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly snackbar: MdcSnackbar,
    protected readonly restService: RestService,
    protected readonly authService: AuthService,
    protected readonly cellaService: CellaService,
    protected readonly metacomService: MetacomService,
    protected readonly transactionService: TransactionService,
    protected readonly entities: EntityManager,
    protected readonly dialogService: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {}

  get canSave() {
    return (
      this.addressType &&
      this.deliveryDate &&
      this.projectId &&
      this.assignedItems.length > 0 &&
      this.comment?.length > 0
    );
  }

  get assignedItems() {
    return this.response
      ? chain(this.response.value)
          .map((e) => e.items)
          .flatten()
          .filter((e) => e.amount !== null)
          .value()
      : [];
  }

  async ngOnInit() {
    this.images =
      (await this.configurationService.findOne("shop-preset"))?.value?.data ??
      {};

    if (this.settings.fixedAddressType) {
      this.addressType = this.settings.fixedAddressType;
    }

    if (this.settings.fixedProject) {
      this.projectId = this.settings.fixedProject.id;
      this.project = this.projectService.concept({
        description: this.settings.fixedProject.description,
      });
    }

    await this.fetch();
  }

  protected async fetch() {
    const response = await this.metacomService.queryTableAsync<any>({
      setName: "metacom",
      tableName: this.settings.tableName,
    });

    if (!response.hasError()) {
      this.response = new RestResponse(
        Array.from(
          this.getGroups(
            chain(response.value)
              .map((e) => new ShopItemDto(e, this.images))
              .orderBy((e) => e.orderId, "asc")
              .value()
          )
        )
      );
    }
  }

  protected *getGroups(items: ShopItemDto[]) {
    let group: ShopItemGroup = null;

    for (const item of items) {
      if (item.middel === this.GROUP_ID) {
        if (group) {
          yield group;
        }

        group = new ShopItemGroup(item, []);
      } else {
        group.items.push(item);
      }
    }

    if (group) {
      yield group;
    }
  }

  async send() {
    const deliverDateNormal = this.deliveryDate.jsdate;

    const request = new ShopRequest({
      address: this.getAddress(this.project),
      projectId: `${this.settings.projectIdPrefix || ""}${
        this.nativeProjectId
      }`,
      projectDescription: this.project.description,
      addressType: this.getAddressDocumentHeader(this.addressType),
      deliveryDate: deliverDateNormal,
      user: this.authService.user,
      items: this.assignedItems,
      image: this.image,
      comment: this.comment,
      documentType: this.settings.documentType,
      companyId: this.settings.companyId ?? this.DEFAULT_COMPANY_ID,
      sourceDocumentNumber: this.settings.sourceDocumentNumber,
    });

    const response = await this.transactionService.perform(() =>
      this.cellaService.writeTransactionDocument(
        new ShopDocument().create(request)
      )
    );

    if (!response.hasError()) {
      const shopOrderId = response.value.CreatedDocumentNumber;
      const mailResponse = await new ShopMailer(
        this.restService,
        this.cellaService
      ).send(request, shopOrderId);

      if (!mailResponse.hasError()) {
        this.snackbar.open("Verzonden", "Ok", { leading: true });
        this.fetch();
      }
    }
  }

  protected getAddressDocumentHeader(addressType: string) {
    switch (addressType) {
      case "company":
        return "Emmeloord";
      case "prefabin":
        return "Kampen";
      case "project":
        return "Project";
      case "misc":
        return "Overige";
    }
  }

  protected getAddress(project: Project): Address {
    switch (this.addressType) {
      case "prefabin":
        return new Address({
          address: "Betonstraat 13",
          zipCode: "8263 BL",
          city: "Kampen",
        });
      case "company":
        return new Address({
          address: "Titaniumweg 10",
          zipCode: "8304 BR",
          city: "Emmeloord",
        });
      case "project":
        return new Address({
          address: `${project.buildingStreet} ${project.buildingHouseNumber}`,
          zipCode: project.buildingZipCode,
          city: project.buildingCity,
        });
      case "misc":
        return new Address({
          address: "-",
          zipCode: "-",
          city: "-",
        });
    }
  }

  async addFiles(files: NgxFileDropEntry[]) {
    this.image = first(
      await Promise.all(
        (files || [])
          .filter((e) => e.fileEntry.isFile)
          .map((e) => e.fileEntry as FileSystemFileEntry)
          .map((e) => this.destructFile(e))
      )
    );
  }

  protected destructFile(entry: FileSystemFileEntry) {
    return new Promise<File>((resolve) => entry.file((f) => resolve(f)));
  }

  openImage(item: ShopItemDto) {
    return this.dialogService.open(this.changeDetector, ImageDialogComponent, {
      data: {
        url: `documents/view/${item.imageDocumentMetaId}`,
        title: `${item.middel} - ${item.middel_naam}`,
      },
    });
  }
}

interface ShopComponentSettings {
  readonly tableName: string;
  readonly projectTableName: string;
  readonly projectIdPrefix: string;
  readonly conditionFilter: boolean;
  readonly documentType?: string;
  readonly sourceDocumentNumber?: string;

  readonly fixedProject?: {
    readonly id: string;
    readonly description: string;
  };

  readonly fixedAddressType?: string;
  readonly companyId?: number;
}
